import { useRef } from 'react';
import arrow_08 from '../../assets/images/arrow_08.png';
import bztmb from '../../assets/images/bztmb.png';
import Bzt from '../../assets/images/bztmes.png';
import Bzware from '../../assets/images/bzwareProject.png';
import Highlight05 from '../../assets/images/highlight_05.png';
import LogoBztalk2 from '../../assets/images/logobztalk2.png';
import Bzware2 from '../../assets/images/logoBzware2.png';
import LogoTms2 from '../../assets/images/logoTms2.png';
import Tms from '../../assets/images/tms.png';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ClientSlider from '../../components/home/ClientSlider';

const Solution = () => {
  const swiperRef = useRef(null);

  return (
    <div className="solution custom-hidden  bg-[#F3F6FE]">
      <div className=" m-auto md:w-[704px] lg:w-[704px] xl:w-[1200px] ">
        <div className="relative pt-16 ">
          <div className="relative m-auto xs:w-[80%] xl:w-[100%]">
            <p className=" relative font-bold xs:w-[311px] xs:text-[30px] sm:block  md:w-[500px] md:text-[35px] xl:w-[600px] xl:text-[40px]">
              Our Comprehensive Solution
              <div className="absolute top-0 right-6 xs:-right-4 sm:-right-6 md:-right-6 lg:-right-6 xl:right-2">
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5726 9.76832C9.15909 7.78441 7.20346 5.4218 5.7339 2.66919C5.43998 2.12093 4.75609 1.9118 4.20218 2.20571C3.65392 2.49962 3.44475 3.18355 3.73866 3.73746C5.35518 6.75007 7.49174 9.33877 10.137 11.5149C10.6174 11.9105 11.3352 11.8427 11.7308 11.3622C12.1265 10.8762 12.053 10.164 11.5726 9.76832Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2855 2.04182C10.4098 4.3253 10.5229 6.60878 10.6642 8.89226C10.7038 9.514 11.2407 9.98878 11.8625 9.94921C12.4842 9.90965 12.959 9.37269 12.9194 8.75095C12.7781 6.47313 12.665 4.19531 12.5407 1.91184C12.5068 1.29179 11.9698 0.81418 11.3481 0.849224C10.7264 0.884267 10.2459 1.41839 10.2855 2.04182Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.6767 2.50528C17.2297 4.68702 15.5907 6.69917 13.9402 8.73395C13.5446 9.22004 13.618 9.93224 14.1041 10.3222C14.5902 10.7179 15.3024 10.6444 15.6924 10.1583C17.388 8.07266 19.0724 5.9983 20.5646 3.75439C20.9093 3.23439 20.7624 2.53354 20.2424 2.18876C19.728 1.84397 19.0215 1.98528 18.6767 2.50528Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.2817 10.6162C20.3591 10.7914 16.1935 10.7971 12.2709 10.5484C11.6491 10.5144 11.1122 10.9836 11.0726 11.611C11.0331 12.2327 11.5078 12.7697 12.1296 12.8092C16.1313 13.0579 20.3818 13.0523 24.3835 12.8771C25.0053 12.8488 25.4913 12.3175 25.463 11.6958C25.4348 11.074 24.9035 10.5879 24.2817 10.6162Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1339 11.334C12.553 14.2957 15.057 17.201 17.3009 20.2984C17.6683 20.8014 18.3748 20.9144 18.8835 20.547C19.3866 20.1796 19.4996 19.4731 19.1322 18.9701C16.86 15.8388 14.3279 12.8996 11.8805 9.90398C11.4848 9.42355 10.7726 9.35009 10.2922 9.74574C9.80608 10.1414 9.73824 10.8536 10.1339 11.334Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8665 12.1933C11.4996 15.2794 11.6748 18.2298 11.72 21.3555C11.7257 21.9772 12.24 22.4746 12.8618 22.4689C13.4892 22.4576 13.9865 21.9489 13.9752 21.3215C13.93 18.0489 13.7435 14.9685 13.0822 11.7354C12.9522 11.125 12.3587 10.7293 11.7426 10.8593C11.1322 10.9837 10.7365 11.5828 10.8665 12.1933Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9686 11.9447C9.13728 15.6074 8.19336 19.6769 6.23206 23.2604C5.93249 23.803 6.13593 24.4926 6.68419 24.7921C7.23245 25.0917 7.91641 24.8882 8.21597 24.3456C10.1886 20.7395 11.1438 16.6473 12.992 12.9565C13.269 12.3969 13.0429 11.7186 12.489 11.436C11.9294 11.1591 11.2512 11.3852 10.9686 11.9447Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9764 11.1135C8.33078 11.7579 4.69078 12.0518 1.02252 12.487C0.400781 12.5605 -0.040089 13.12 0.0333893 13.7418C0.106868 14.3635 0.666485 14.8044 1.28822 14.7309C4.99605 14.2957 8.68126 13.9905 12.3721 13.3405C12.9826 13.2274 13.3952 12.6396 13.2878 12.0292C13.1747 11.4131 12.5925 11.0005 11.9764 11.1135Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5133 9.39562C13.7616 9.36171 13.0494 9.31082 12.3146 9.20343C11.6929 9.113 11.122 9.54256 11.0316 10.1587C10.9411 10.7747 11.3707 11.3513 11.9867 11.4417C12.8007 11.5604 13.5863 11.6169 14.4115 11.6508C15.0333 11.6791 15.5646 11.1986 15.5872 10.5712C15.6154 9.94951 15.1351 9.42388 14.5133 9.39562Z"
                    fill="#1F2A37"
                  />
                </svg>
              </div>
            </p>
            <div>
              <svg
                width="239"
                height="16"
                viewBox="0 0 484 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="xs:w-[239px] sm:w-[300px] md:w-[382px] lg:w-[480px]"
              >
                <path
                  d="M2 14C71.776 4.94452 265.462 -7.73316 482 14"
                  stroke="#54BD95"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col gap-24 ">
          {/* ----- Bzware ----*/}
          <div className="m-auto mt-10 items-center justify-center gap-28 xs:w-[80%] lg:flex xl:flex xl:w-[100%]">
            <div className="flex   flex-col gap-4">
              <div className="flex items-center gap-3 ">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={Bzware2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent ">
                  BZware
                </p>
              </div>
              <h2 className="font-bold text-[#1F2A37] xs:text-[24px] sm:text-[28px] md:text-[32px] xl:w-full ">
                Optimize & Manage
              </h2>
              <div className="relative m-auto xl:hidden">
                <img src={Bzware} alt="" className="object-cover" />
              </div>
              <p className=" sm:[350px] text-base font-normal text-[#4B5563] md:w-[604px] xl:w-[482px]">
                BZware offers the ultimate solution for workforce and project{' '}
                management, enabling seamless planning, tracking, and execution
                of tasks. With integrated tools to streamline workflows,
                allocate resources, and keep teams aligned, BZware enhances
                productivity and fosters collaboration. From project tracking to
                HR management, everything you need to drive efficiency and
                impact is in one platform.
              </p>
            </div>
            <div className="w- relative xs:hidden sm:hidden md:hidden xl:block">
              <div className="absolute -top-16 -right-6">
                <img src={Highlight05} alt="" />
              </div>

              <img src={Bzware} alt="" className="object-cover" />
            </div>
          </div>
          {/*---- TMS ------  */}
          <div className="relative m-auto items-center justify-between gap-28 xs:w-[80%] lg:flex xl:mt-8 xl:flex xl:w-[100%]">
            <div className="absolute -top-36  left-[460px] z-30 sm:hidden md:hidden xl:block">
              <img src={arrow_08} alt="" className=" " />
            </div>
            <div className="relative xs:hidden sm:hidden md:hidden xl:block">
              <img src={Tms} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="relative  flex flex-col gap-4">
              <div className="flex items-center gap-3">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={LogoTms2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent">
                  TMS
                </p>
              </div>
              <div className="absolute top-6 right-0 xs:hidden sm:hidden md:hidden xl:block">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.32998 15.0382C5.84691 14.4976 7.44319 13.7793 8.67416 12.7392C10.1362 11.5039 10.7307 9.91289 11.13 8.23817C11.6426 6.08658 11.8475 3.79461 12.4691 1.63953C12.6992 0.839221 13.1421 0.536703 13.3322 0.402447C13.8125 0.0633184 14.2981 -0.0273304 14.7549 0.00666959C15.2963 0.0459004 16.04 0.250758 16.529 1.1583C16.5988 1.28819 16.6894 1.4861 16.7505 1.75723C16.7949 1.956 16.8237 2.57758 16.8708 2.83388C16.9885 3.46506 17.087 4.09626 17.1794 4.73093C17.4871 6.84329 17.6641 8.63742 18.6362 10.578C19.9552 13.2126 21.2769 14.8245 23.0693 15.5385C24.8024 16.229 26.8746 16.0991 29.5223 15.5578C29.7742 15.4967 30.0235 15.4444 30.2702 15.4017C31.4376 15.1977 32.5535 15.964 32.7828 17.127C33.0121 18.2891 32.2702 19.4215 31.1133 19.677C30.8718 19.7301 30.6338 19.7807 30.3984 19.8269C26.8206 20.7127 22.6787 23.8738 20.2716 26.6417C19.5297 27.4952 18.4435 29.8813 17.3354 31.4035C16.5177 32.5263 15.5988 33.2665 14.8273 33.528C14.3103 33.7041 13.8744 33.6772 13.5144 33.5882C12.9913 33.4592 12.5572 33.1758 12.2241 32.7251C12.0428 32.4784 11.8745 32.148 11.7943 31.726C11.7559 31.5229 11.7516 31.0068 11.7525 30.7732C11.5267 29.9964 11.2503 29.2379 11.0489 28.4542C10.5686 26.5842 9.62615 25.4003 8.50677 23.8363C7.45974 22.3726 6.33516 21.4528 4.6866 20.7188C4.47214 20.6665 2.74162 20.2428 2.13049 19.9995C1.23777 19.643 0.812312 19.0458 0.658004 18.7241C0.395594 18.1783 0.368592 17.7015 0.4209 17.304C0.49849 16.7172 0.761773 16.2151 1.22906 15.8097C1.51849 15.5578 1.95091 15.3128 2.52978 15.1933C2.97701 15.1 4.16347 15.046 4.32998 15.0382ZM14.482 11.9903C14.5623 12.1691 14.6477 12.3487 14.7384 12.53C16.6703 16.3886 18.8306 18.5428 21.4564 19.5881L21.5445 19.6221C19.7878 20.9263 18.1976 22.3839 16.9823 23.7814C16.4819 24.3568 15.8194 25.552 15.1037 26.7778C14.4533 24.6637 13.3897 23.1694 12.0524 21.2994C11.0306 19.8723 9.96006 18.7982 8.64453 17.9229C9.6654 17.399 10.6392 16.7852 11.4874 16.0686C12.8997 14.8751 13.8334 13.4924 14.482 11.9903Z"
                    fill="#1F2A37"
                  />
                </svg>
              </div>

              <h2 className="font-bold text-[#1F2A37] xs:text-[24px] sm:text-[28px] md:text-[32px] ">
                Simplify Your QA Process
              </h2>
              <div className="relative m-auto  xl:hidden">
                <img src={Tms} alt="" className="h-full w-full object-cover" />
              </div>
              <p className="text-base font-normal text-[#4B5563] xl:w-[484px]">
                TMS provides a complete solution for managing your quality
                assurance process, from planning and executing test cases to
                tracking results and ensuring quality at every stage. With tools
                to organize workflows, allocate resources, and maintain team
                alignment, TMS helps streamline your quality assurance efforts.
                From simple quality checks to complex scenarios, our platform
                empowers your team to deliver reliable, high-quality outcomes
                efficiently and consistently.
              </p>
            </div>
          </div>
          {/*---- Bztalk ------  */}
          <div className="relative  m-auto items-center justify-between gap-28 xs:w-[80%] lg:flex xl:flex  xl:w-[100%] xl:pt-10">
            <div className="relative flex   flex-col gap-3 ">
              <div className="flex items-center gap-3">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={LogoBztalk2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent">
                  BZtalk
                </p>
              </div>
              <div className="absolute -top-2 right-0 xs:hidden sm:hidden md:hidden xl:block">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5213 27.7684C11.3317 27.5823 11.1407 27.3962 10.9484 27.2114C8.98726 25.3316 7.04143 23.4496 5.21573 21.4337C4.77337 20.9455 3.94282 19.9983 3.10879 19.5108C2.51226 19.1621 1.89488 19.0163 1.33029 19.0886C0.970572 19.1344 0.4449 19.3156 0.181011 20.0052C0.136567 20.1212 0.0838008 20.3205 0.0629674 20.5746C0.0296341 20.9857 0.062257 21.7233 0.0726737 21.8253C0.218507 23.3128 0.364335 24.7351 0.631696 26.2066C0.87753 27.5608 1.04558 28.9254 1.31016 30.2774C1.70599 32.3004 2.21573 34.2476 2.95879 36.1739C3.23448 36.8892 4.03934 37.2455 4.75462 36.9698C5.4692 36.6941 5.82615 35.8899 5.55045 35.1747C4.86642 33.4004 4.40046 31.6073 4.03657 29.7441C3.77407 28.4045 3.60808 27.0517 3.36433 25.7101C3.206 24.8371 3.09349 23.9823 2.99766 23.1198L3.15669 23.2982C5.02683 25.3628 7.01782 27.2913 9.02615 29.2163C9.77754 29.9372 10.5032 30.6788 11.2664 31.3879C11.3282 31.4781 11.6136 31.892 11.7539 32.0337C11.9872 32.2698 12.2393 32.4017 12.4588 32.467C13.0164 32.6337 13.5824 32.5719 14.0769 31.9469C14.447 31.4788 14.6602 30.7531 14.6442 29.8864C14.6227 28.6955 14.1706 27.1254 14.0748 26.4018C13.4678 21.8233 12.4859 17.2629 12.2248 12.6448C12.1817 11.8795 11.5255 11.2934 10.7602 11.3365C9.99491 11.3802 9.40808 12.0364 9.45183 12.8017C9.71641 17.4892 10.7046 22.1191 11.3206 26.767C11.3539 27.0143 11.4331 27.3663 11.5213 27.7684Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.6573 8.78966C22.0823 9.20703 20.5046 9.5709 19.2393 10.6528C17.9157 11.7855 17.2428 13.4306 17.0032 15.264C16.6976 17.6029 17.1074 20.2563 17.3615 22.1473C17.4935 23.1265 17.5497 24.4911 17.8351 25.7001C18.081 26.7397 18.4997 27.6709 19.1379 28.323C20.1407 29.3473 21.6796 29.4508 23.258 28.8119C25.0816 28.0737 26.8976 26.3702 27.3914 25.3772C27.7331 24.691 27.4532 23.8563 26.7664 23.5154C26.0803 23.1737 25.2462 23.4536 24.9046 24.1397C24.5914 24.7695 23.3726 25.7688 22.2157 26.2376C21.9574 26.3417 21.7018 26.4195 21.4601 26.439C21.3344 26.4494 21.2087 26.4674 21.1233 26.3806C20.7046 25.9522 20.5574 25.2556 20.4358 24.5522C20.2657 23.5716 20.2184 22.5473 20.115 21.7765C19.8872 20.0855 19.4845 17.7154 19.7574 15.6237C19.9039 14.5022 20.2351 13.457 21.0455 12.764C21.9858 11.9598 23.1969 11.7855 24.3684 11.4751C25.1094 11.2793 25.5518 10.5181 25.3553 9.77716C25.1594 9.03619 24.3983 8.59383 23.6573 8.78966Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5507 16.1082C22.3306 16.7728 21.0264 17.456 19.7188 17.9255C18.9973 18.1845 18.6216 18.9804 18.8799 19.7019C19.1389 20.4234 19.9348 20.7991 20.6563 20.5401C22.0966 20.0234 23.5361 19.2797 24.8792 18.5484C25.5521 18.1818 25.8014 17.3373 25.4347 16.6644C25.0681 15.9908 24.2244 15.7422 23.5507 16.1082Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.3243 16.8455C39.3104 15.6191 38.2389 14.4121 37.1069 13.3094C37.0145 13.2198 35.8729 12.2795 35.1944 11.9281C34.7368 11.6913 34.3312 11.6483 34.0937 11.6712C33.4027 11.7378 32.9812 12.1121 32.8083 12.7524C32.643 13.3663 32.5833 14.0059 32.5833 14.6587C31.5562 12.733 30.6167 10.7524 30.0458 8.66701C29.9986 8.49479 29.9604 8.41423 29.9541 8.39964C29.7618 7.97464 29.4889 7.83017 29.3916 7.77323C28.9139 7.49267 28.4833 7.53993 28.1201 7.70312C28.0757 7.72257 27.0965 8.15728 27.3305 9.2677C27.3493 9.35728 27.509 9.99546 27.5555 10.2448C27.5965 10.4614 27.6854 10.6566 27.8097 10.8218C28.7666 13.5705 30.2305 16.167 31.6354 18.7024C31.9389 19.2503 32.1757 19.8149 32.3319 20.4212C32.3778 20.5982 32.3965 20.9989 32.4368 21.1954C32.5055 21.5281 32.6416 21.751 32.7284 21.8649C33.1798 22.4579 33.7361 22.5184 34.2493 22.3663C34.4687 22.3017 34.9083 22.1538 35.1757 21.517C35.777 20.0864 35.709 18.5066 35.5347 16.9149C35.4833 16.4441 35.4194 15.9732 35.3805 15.5079C36.616 16.7357 37.7722 18.0899 38.859 19.4434C38.9784 19.592 40.1882 20.9448 40.5097 21.1462C41.3139 21.6517 41.9382 21.2718 42.2055 21.0454C42.6132 20.6996 42.934 20.1059 43.0646 19.3496C43.2326 18.3712 43.1 17.0246 43.0868 16.4829C42.9986 12.8371 42.8666 9.02603 42.0479 5.46631C41.8763 4.71909 41.1305 4.2524 40.3833 4.42392C39.6361 4.59545 39.1694 5.342 39.341 6.08853C40.118 9.46909 40.2257 13.0892 40.3097 16.5503C40.3118 16.6302 40.3173 16.7302 40.3243 16.8455Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.8744 4.54273C46.3778 6.97676 46.882 9.38855 47.2112 11.8913C47.3112 12.6517 48.0091 13.1879 48.7695 13.0879C49.5292 12.9879 50.0653 12.2899 49.9653 11.5295C49.5014 7.99966 48.6987 4.64829 48.0264 1.16634C47.7674 -0.17949 46.4883 0.00872362 46.4098 0.0226125C46.2369 0.0531681 45.2945 0.253861 45.2633 1.35733C45.2612 1.42469 45.3063 1.90105 45.3139 2.08577C45.3306 2.50869 45.3223 2.9219 45.3007 3.34551C45.2757 3.8337 45.5056 4.27607 45.8744 4.54273Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.4685 18.8961C49.6095 18.4669 49.538 17.9767 49.2352 17.5968C48.7567 16.9975 47.8824 16.8996 47.2831 17.3781C46.7296 17.8197 46.6262 18.3579 46.7005 19.0163C46.7866 19.7781 47.4755 20.3267 48.2373 20.2399C48.9338 20.1614 49.4519 19.578 49.4685 18.8961Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.25 45.6878C23.1083 45.3878 22.9945 45.151 22.9674 45.1003C21.6771 42.6871 20.2771 40.3058 18.7528 38.0329C18.3257 37.3961 17.4625 37.226 16.8257 37.6531C16.1889 38.0802 16.0188 38.9434 16.4459 39.5802C17.9188 41.776 19.2708 44.0781 20.518 46.41C20.6132 46.5878 21.2472 48.1677 21.7 48.8267C22.1104 49.4232 22.6257 49.6364 22.9208 49.6802C23.3896 49.7496 24.1229 49.5816 24.7181 48.7871C25.4952 47.7503 26.5028 44.9802 26.6687 44.5205C26.9729 43.6746 27.2306 42.7871 27.4694 41.9239C27.9222 40.2864 28.1826 38.6197 28.4153 36.9406C28.5208 36.1809 27.9896 35.4795 27.2299 35.3739C26.4709 35.2691 25.7688 35.8003 25.6639 36.5594C25.4479 38.117 25.2125 39.6642 24.7924 41.1829C24.5715 41.9795 24.3354 42.7996 24.0549 43.5802C23.9701 43.8142 23.641 44.7809 23.25 45.6878Z"
                    fill="#1F2A37"
                  />
                </svg>
              </div>
              <h2 className="flex gap-2 font-bold text-[#1F2A37]  xs:text-[24px] sm:text-[28px] md:text-[32px] xl:w-full ">
                <span className="xs:hidden sm:block">Seamless</span> Team
                Communication
              </h2>
              <div className="    md:block xl:hidden">
                <div className="">
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className=" h-full max-w-[618px] "
                  >
                    <SwiperSlide>
                      <div className="pb-6">
                        <img
                          src={Bzt}
                          alt=""
                          className={` h-auto w-auto object-cover  `}
                          // onClick={() => handleIndicatorClick(false)}
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="pb-6">
                        <img
                          src={bztmb}
                          alt=""
                          className={` h-auto w-auto  object-cover `}
                          // onClick={() => handleIndicatorClick(true)}
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <p className=" text-base font-normal  text-[#4B5563] xl:w-[504px]">
                BZTALK is your ultimate messaging platform, designed to enhance
                communication within teams. With features like channels for
                group discussions and direct messaging for one-on-one
                conversations, you can easily share ideas, collaborate on
                projects, and stay connected. Our intuitive interface ensures
                that your team can communicate effectively, whether you’re in
                the office or working remotely. Experience a new level of
                collaboration with BZTALK, where every conversation matters.
              </p>
            </div>
            <div className="absolute -top-28 right-[450px] xs:hidden sm:hidden md:hidden xl:block">
              <svg
                width="152"
                height="152"
                viewBox="0 0 152 152"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3545_3223)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M108.446 62.7538C121.354 73.6263 132.25 87.0746 134.956 102.567C135.949 108.258 135.256 113.958 134.609 119.79C134.551 120.303 134.088 120.674 133.576 120.616C133.065 120.559 132.693 120.096 132.75 119.586C133.379 113.93 134.077 108.404 133.114 102.891C130.45 87.6318 119.571 74.4592 106.804 63.8136C106.682 63.8936 106.561 63.9755 106.439 64.0573C103.773 65.8574 99.3165 66.3627 94.3716 65.9133C87.1933 65.263 78.9721 62.6678 74.0151 59.6815C71.7277 58.3049 70.122 56.8088 69.4924 55.4045C68.9667 54.2348 69.0196 53.0956 69.75 52.0315C70.3542 51.1551 71.2497 50.5066 72.3891 50.0809C73.91 49.5135 75.8949 49.3409 78.146 49.4936C86.4755 50.0585 98.5705 55.0341 102.906 58.327C104.265 59.3585 105.61 60.4171 106.94 61.5054C107.713 61.0102 108.45 60.5344 109.109 60.0163C110.077 59.2556 110.869 58.41 111.286 57.2283C112.058 55.0479 111.681 52.7824 110.747 50.613C109.253 47.1388 106.342 43.9122 103.966 41.5942C90.6034 28.5561 72.5732 25.1826 55.4384 25.3607C38.1094 25.5394 21.6765 29.3584 11.7399 30.7005C11.2285 30.7684 10.7563 30.4095 10.6885 29.8987C10.6183 29.3885 10.9781 28.9171 11.4889 28.8474C21.48 27.4986 38.0004 23.6719 55.4184 23.491C73.0306 23.3094 91.5367 26.8554 105.273 40.2572C107.799 42.7245 110.873 46.1778 112.465 49.8744C113.587 52.4887 113.977 55.2243 113.049 57.8495C112.507 59.3801 111.514 60.5029 110.265 61.4849C109.705 61.925 109.09 62.3387 108.446 62.7538ZM105.295 62.5739C102.889 64.1343 98.9191 64.4501 94.5405 64.0522C87.6428 63.4274 79.7422 60.9496 74.9779 58.0814C73.3823 57.1194 72.1477 56.1298 71.4813 55.1419C70.9985 54.4285 70.8479 53.7327 71.29 53.0912C71.7982 52.353 72.6412 51.9002 73.6939 51.628C74.9064 51.313 76.385 51.2464 78.0215 51.3586C86.0098 51.9004 97.6188 56.6569 101.775 59.8144C102.957 60.7134 104.133 61.634 105.295 62.5739Z"
                    fill="#1F2A37"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M132.221 122.013C132.617 121.841 133.087 121.494 133.575 121.109C134.679 120.239 135.807 119.119 136.361 118.608C136.74 118.259 137.332 118.282 137.682 118.662C138.032 119.042 138.007 119.633 137.627 119.984C136.95 120.607 135.454 122.09 134.136 123.023C133.459 123.503 132.807 123.837 132.3 123.941C131.802 124.041 131.42 123.931 131.11 123.71C130.784 123.477 130.515 123.054 130.368 122.432C130.208 121.749 130.164 120.724 129.958 119.641C129.786 118.731 129.497 117.765 128.815 116.998C128.472 116.613 128.505 116.021 128.892 115.679C129.276 115.337 129.868 115.37 130.209 115.756C131.49 117.193 131.813 119.128 131.998 120.675C132.052 121.136 132.157 121.698 132.221 122.013Z"
                    fill="#1F2A37"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3545_3223">
                    <rect
                      width="123.32"
                      height="123.32"
                      fill="white"
                      transform="matrix(0.258819 0.965926 0.965926 -0.258819 0 31.9177)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="  xs:hidden xl:block">
              <Swiper
                ref={swiperRef}
                pagination={true}
                modules={[Pagination]}
                slidesPerView={1}
                className="h-full w-[618px]  "
              >
                <SwiperSlide>
                  <div className="pb-6 ">
                    <img
                      src={Bzt}
                      alt=""
                      className={`  h-full w-full object-cover  `}
                      // onClick={() => handleIndicatorClick(false)}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-6 ">
                    <img
                      src={bztmb}
                      alt=""
                      className={` h-full object-cover   `}
                      // onClick={() => handleIndicatorClick(true)}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* ----- End ----- */}
        </div>

        <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
          <ClientSlider
            infinite={true}
            autoplay={true}
            autoplayLg={true}
            classImage={
              'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
            }
          />
        </section>
      </div>
    </div>
  );
};

export default Solution;
