import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MailForm = (data) => {
  const { register, handleSubmit, reset } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const form = useRef();

  const onSubmit = () => {
    emailjs
      .sendForm(
        'service_aavfxnv',
        'template_z1aqp58',
        form.current,
        'qLPBP66eMVIJU7wfh'
      )
      .then(
        (result) => {
          setIsOpen(true);
          reset();
          console.log('result', result);
        },
        (error) => {
          toast.error('Error! An error occurred. Please try again later', {
            position: toast.POSITION.TOP_CENTER
          });
          console.log(error.text);
        }
      );
  };
  setTimeout(() => {
    setIsOpen(false);
  }, 2500);

  return (
    <div>
      <form
        className={data?.classForm}
        ref={form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <input
            className={`${data?.classInput} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            type="text"
            {...register('name', { required: true })}
            placeholder="Your name *"
          />
        </div>

        <div className="mt-3">
          <input
            className={`${data?.classInput} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            type="email"
            {...register('email', { required: true })}
            placeholder="Your email *"
          />
        </div>

        <div className="mt-3">
          <textarea
            className={`${data?.classTextarea} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            {...register('message', { required: true })}
            placeholder="Your message *"
          ></textarea>
        </div>

        <button
          className={`${data?.classBtn} mt-2 transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg`}
          type="submit"
        >
          Send
        </button>
      </form>

      {/* {isOpen && ( */}
      <div
        className={`fixed inset-0 flex items-center justify-center  transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
      >
        <div
          className={`w-10/12 transform rounded-lg bg-white p-6 text-center shadow-lg  transition-transform duration-300 ease-in-out md:w-1/3 ${
            isOpen ? 'scale-100' : 'scale-95'
          }`}
        >
          <div className="flex justify-center">
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30" cy="30" r="30" fill="#06941D" />
              <path
                d="M44.3931 20.094L25.7948 38.6923L17.341 30.2385"
                stroke="white"
                strokeWidth="6.93642"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <h2 className="my-2 text-lg font-bold">
            Thank You for Contacting Us!
          </h2>
          <p className="font-normal text-[#6B7280]">
            Your request has been sent successfully. Our team will get in touch
            with you shortly to assist.
          </p>
          <button
            className="btn-gradient-1 mt-4 w-[80px] rounded border px-4 py-1.5"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default MailForm;
