import { useState } from 'react';
import ClientSlider from '../../components/home/ClientSlider';

import AboutFirst from '../../assets/images/about-1.png';
import AboutSecond from '../../assets/images/about-2.png';
import HistoryAct from '../../assets/images/history-active.png';
import History from '../../assets/images/history.png';

const historyBz = [
  {
    year: 2022,
    content: [
      'Food Safety Information System Development Project for VFA from MFDS / Local Coordinator and System Development',
      'Enhancing financial transaction processing capacity for Core Banking System (Vietnam AgriBank)',
      'Information System Audit Services for Project for Smart City Establishment of Quang Nam Province (Tam Ky City), Vietnam',
      'PMO Service for Information System of the National Technician Qualification in Vietnam',
      'MK Live Implementation for Vi Sang Edu Vietnam',
      'Digital Transformation Online Forum 2022 (Vietnam-Korea) - Organizer'
    ]
  },
  {
    year: 2021,
    content: [
      'Food Safety Information System Development Project for VFA from MFDS / Local Coordinator and Development Partner',
      'Online Opinion Analysis of Vietnamese on South Korea after Covid-19 (Ministry of Foreign Affairs)',
      'System Maintenance and Improvement Project for Vi Sang Edu Vietnam (Cont’d)',
      'Pre Feasibility Study for MPI (Ministry of Planning and Investment) with KOICA',
      'Outsourcing of the illustrate for Korean Text Book'
    ]
  },
  {
    year: 2020,
    content: [
      'eGov Consulting for Supreme People’s Court of Vietnam / Coordinator and Consulting',
      'Research on technology business for family companies to advance into new southern countries (HoSeo Univ)',
      'Vietnam real-time financial network (NAPAS) connection project Kookmin Bank',
      'Outsourcing with SimTech Co., Ltd.',
      'Outsourcing with RSN Co., Ltd.',
      'NIPA Research Project for Mind Map Software Solution',
      'Pilot System : Mind Map Collaboration System (Vietnam Gov’)',
      'NotiU – Vietnam Central Government',
      'Development – Web Crawling Solution'
    ]
  },
  {
    year: 2019,
    content: [
      'Outsourcing with RSN Co., Ltd.',
      'Consulting ISP for Supreme People’s Court of Vietnam',
      'Development eXCampus with tomato System Co., Ltd.',
      'Member of the Vietnam-Korea Friendship Association.'
    ]
  },
  {
    year: 2018,
    content: [
      'EAMS for Vietnam National University Hanoi',
      'Development eXCampus with tomato System Co., Ltd.',
      'Biometrics Project with Bac Ninh Police',
      'eLearning : Vietnam Marine Univ - Crew Manning Center'
    ]
  },
  {
    year: 2017,
    content: [
      'Outsourcing with IamZ Co., Ltd.',
      'Development eXCampus with tomato System Co., Ltd.',
      'eLearning : HCM City, Dept. of Education',
      'eLearning : People’s security Univ in Hanoi',
      'eLearning : Bach Mai Hospital'
    ]
  },
  {
    year: 2015,
    content: [
      'Started service for the S&B (Search Bidding) Service',
      'eLearning : Univ of Communication and Transport',
      'eLearning : Agribank, Vietnam Airline, Da Nang City'
    ]
  }
];

const About = () => {
  const [yearActive, setYearActive] = useState(2022);
  const [content, setContent] = useState(historyBz[0]?.content);
  const [hoverYear, setHoverYear] = useState();

  const showContent = (selectYear) => {
    setYearActive(selectYear);
    for (let index = 0; index < historyBz.length; index++) {
      const data = historyBz[index];
      if (selectYear === data?.year) {
        setContent(data?.content);
      }
    }
  };

  return (
    <div className="about bg-[#F3F6FE]">
      {/* Intro */}
      <section className="custom-hidden container pt-14 md:pt-16 lg:pt-28">
        <div className="lg:grid-rows-2 lg:grid lg:grid-flow-col lg:gap-8">
          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#412B26]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold">
              Who we are
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg">
              BZCOM was founded in Hanoi in 2017. In BZCOM, we believe in
              “consensus - communication - consideration” philosophy. We always
              consent with our employees, partners, as well as clients as the
              work is being carried out. To avoid unneccesary misunderstanding,
              communication is maintained clear and indispensable at work.
              Finally, we treat our employees, partners, and clients with utmost
              consideration and respect.
            </p>
          </div>

          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#412B26]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold">
              What we do
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg">
              To keep it short, we consult and we develop. We have been working
              with various customers who come from all sorts of areas, and we do
              understand that IT is not an easy topic to deal with. We help our
              customers understanding what they want and what they need, then
              offer to turn their wishes into reality.
            </p>
          </div>

          <div className="row-span-3 hidden lg:block">
            <img
              className="rounded object-cover lg:h-full lg:w-full"
              src={AboutSecond}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* Background image */}
      <section className="custom-hidden mx-auto max-w-[375px] px-[1rem] pt-14 sm:max-w-none sm:px-0 md:pt-16 lg:pt-28">
        <img
          className="h-[364px] rounded-2xl object-cover sm:h-full sm:rounded-none"
          src={AboutFirst}
          alt=""
        />

        <div className="container mt-[-65px] text-2xl font-semibold lg:mt-[-112px] lg:text-5xl lg:font-bold">
          <p className="text-[#98DAFF] opacity-60">Our History</p>
          <p className="mt-[-12px] text-[#2081B7] opacity-80">Our History</p>
          <p className="mt-[-12px] text-white">Our History</p>
          <p className="mt-[-12px] text-[#3170A9] opacity-70">Our History</p>
          <p className="mt-[-12px] text-[#325F87] opacity-40">Our History</p>
        </div>
      </section>

      {/* History */}
      <section className="custom-hidden history container pt-14 md:pt-16 lg:pt-28">
        <div className="flex md:grid md:grid-cols-6 md:gap-14">
          {/* Years */}
          <ul className="years mr-6 grid h-[650px] w-[80px] justify-center lg:col-span-2 lg:mr-0 lg:w-full lg:grid-cols-2 lg:grid-rows-14">
            {/* 2022 */}
            <li className="mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2022)}
                onMouseEnter={() => setHoverYear(2022)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2022
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2022 &&
                  hoverYear === 2022 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2022
              </h4>
            </li>
            <li className="z-10">
              {yearActive === 2022 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2022)}
                  onMouseEnter={() => setHoverYear(2022)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2022 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:-rotate-[10deg]  xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

            {/* 2021 */}
            <li className="row-start-3 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2021)}
                onMouseEnter={() => setHoverYear(2021)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2021
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2021 &&
                  hoverYear === 2021 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2021
              </h4>
            </li>
            <li className="z-10 row-start-3 lg:justify-self-center">
              {yearActive === 2021 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2021)}
                  onMouseEnter={() => setHoverYear(2021)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2021 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.85] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg]"></li>

            {/* 2020 */}
            <li className="row-start-5 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2020)}
                onMouseEnter={() => setHoverYear(2020)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2020
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2020 &&
                  hoverYear === 2020 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2020
              </h4>
            </li>
            <li className="z-10 row-start-5">
              {yearActive === 2020 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2020)}
                  onMouseEnter={() => setHoverYear(2020)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2020 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-70 md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4  lg:-rotate-[10deg] xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

            {/* 2019 */}
            <li className="row-start-7 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2019)}
                onMouseEnter={() => setHoverYear(2019)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2019
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2019 &&
                  hoverYear === 2019 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2019
              </h4>
            </li>
            <li className="z-10 row-start-7 lg:justify-self-center">
              {yearActive === 2019 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2019)}
                  onMouseEnter={() => setHoverYear(2019)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2019 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.65] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg]"></li>

            {/* 2018 */}
            <li className="row-start-9 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2018)}
                onMouseEnter={() => setHoverYear(2018)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2018
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2018 &&
                  hoverYear === 2018 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2018
              </h4>
            </li>
            <li className="z-10 row-start-9">
              {yearActive === 2018 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2018)}
                  onMouseEnter={() => setHoverYear(2018)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2018 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-50 md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4  lg:-rotate-[10deg] xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

            {/* 2016-2017 */}
            <li className="row-start-11 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2017)}
                onMouseEnter={() => setHoverYear(2017)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2017
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2017 &&
                  hoverYear === 2017 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2016-2017
              </h4>
            </li>
            <li className="z-10 row-start-11 lg:justify-self-center">
              {yearActive === 2017 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2017)}
                  onMouseEnter={() => setHoverYear(2017)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2017 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="mt-[-35%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.35] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg]"></li>

            {/* 2014-2015 */}
            <li className="row-start-13 mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2015)}
                onMouseEnter={() => setHoverYear(2015)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2015
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2015 &&
                  hoverYear === 2015 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2014-2015
              </h4>
            </li>
            <li className="z-10 row-start-13">
              {yearActive === 2015 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => showContent(2015)}
                  onMouseEnter={() => setHoverYear(2015)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    hoverYear === 2015 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>
          </ul>

          {/* Content */}
          <div className="content col-span-4 h-[650px] pb-8 sm:pb-0">
            <p className="current-year mb-4 text-2xl font-extrabold md:text-4xl lg:hidden">
              {yearActive}
            </p>

            {yearActive === 2022 && (
              <p className="content-highlight mb-6 text-lg font-medium xs:text-xl md:text-2xl">
                Listen to you, answer with solutions.
              </p>
            )}

            {yearActive === 2017 && (
              <p className="content-highlight mb-6 text-lg font-medium xs:text-xl md:text-2xl">
                07/2017 : Established BzCom Company Limited
              </p>
            )}

            <div
              className={`${yearActive === 2022 && 'h-[78%]'} ${
                yearActive === 2020 && 'h-[90%]'
              } ${
                yearActive !== 2015 && 'md:h-full'
              } overflow-hidden overflow-y-auto`}
            >
              {content?.map((data, index) => {
                return (
                  <p
                    key={index}
                    className="mb-6 text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg"
                  >
                    {data}
                  </p>
                );
              })}
            </div>

            {yearActive === 2015 && (
              <p className="content-highlight mb-6 text-lg font-medium xs:text-xl md:text-2xl">
                05/2014 : Established MMG Vietnam JSC
              </p>
            )}
          </div>
        </div>
      </section>

      {/* Client */}
      <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default About;
