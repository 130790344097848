import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import ArrowLeft from '../../assets/icons/arrow-left.svg';
import SolutionBzt from '../../assets/images/bztalkSoluition2.png';
import SolutionsBzware from '../../assets/images/bzwareSolution2.png';
import SulotionTms from '../../assets/images/tmsSolution2.png';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="next-arrow right-[-50px] xl:transition-all"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="prev-arrow left-[-50px] xl:transition-all"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};

const SolutionSlider = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    autoplay: true,
    focusOnSelect: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          centerMode: false,
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="m-auto xl:w-[1200px]">
      <Slider {...settings}>
        <div className="px-1 xs:px-2">
          <div className="solution__slider-item flex justify-center">
            <img
              className="  rounded-2xl object-cover"
              src={SolutionsBzware}
              alt=""
            />
            {/* <div className="item-overlay rounded-2xl">
              <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
                BzWare
              </h4>
            </div> */}
          </div>
        </div>

        <div className=" px-1 xs:px-2">
          <div className="solution__slider-item flex justify-center">
            <img
              className="  rounded-2xl object-cover "
              src={SulotionTms}
              alt=""
            />
            {/* <div className="item-overlay rounded-2xl">
              <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
                TMS
              </h4>
            </div> */}
          </div>
        </div>

        <div className="px-1 xs:px-2">
          <div className="solution__slider-item flex justify-center">
            <img className=" rounded-2xl" src={SolutionBzt} alt="" />
            {/* <div className="item-overlay rounded-2xl">
              <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
                BzTalk
              </h4>
            </div> */}
          </div>
        </div>
      </Slider>
      <div className="h-5 "></div>
    </div>
  );
};

export default SolutionSlider;
